<template>
  <div>
    <SectionHero
      :title="$t('section_hero.title')"
      :subtitle="$t('section_hero.subtitle')"
    >
      <SectionHeroBlock
        :title="$t('section_hero.cta_free_wifi.title')"
        :text="$t('section_hero.cta_free_wifi.text')"
        :button-label="$t('global.connect_wifi_button')"
        button-icon="ph:link-bold"
        :to="{name: 'wifi-connect'}"
      />
    </SectionHero>
    <SectionTwoColumns>
      <template #left-column>
        <img
          src="~/assets/images/app-desktop.jpg"
          alt=""
          class="mx-auto px-4 pb-10"
        >
      </template>
      <template #right-column>
        <SofieText
          :value="page?.template?.tekst"
          class="p:text-primary"
        />
        <div class="flex flex-wrap items-center gap-5 max-sm:justify-center">
          <NuxtLink
            to="https://apps.apple.com/app/apple-store/id6481107984?pt=55702805&ct=internet.jaarbeurs.nl&mt=8"
            target="_blank"
          >
            <img
              src="@/assets/images/app-store.svg"
              alt="Download on the App Store"
            >
          </NuxtLink>
          <NuxtLink
            to="https://play.google.com/store/apps/details?id=nl.jaarbeurs.app&referrer=internet.jaarbeurs.nl"
            target="_blank"
          >
            <img
              src="@/assets/images/google-play.svg"
              alt="Get it on Google Play"
            >
          </NuxtLink>
        </div>
      </template>
    </SectionTwoColumns>
  </div>
</template>

<script lang="ts" setup>
definePageMeta({
  middleware: ['sofie-page'],
});

useSeoMeta({
  title: 'Jaarbeurs Internet',
});

const page = useSofiePage();
</script>
